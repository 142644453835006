import { render, staticRenderFns } from "./TagsField.vue?vue&type=template&id=8427625a&scoped=true&"
import script from "./TagsField.vue?vue&type=script&lang=js&"
export * from "./TagsField.vue?vue&type=script&lang=js&"
import style0 from "./TagsField.vue?vue&type=style&index=0&id=8427625a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8427625a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAutocomplete,VSubheader})

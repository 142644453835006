import { render, staticRenderFns } from "./EditTourInfoPropertiesBox.vue?vue&type=template&id=6ae64b4d&scoped=true&"
import script from "./EditTourInfoPropertiesBox.vue?vue&type=script&lang=js&"
export * from "./EditTourInfoPropertiesBox.vue?vue&type=script&lang=js&"
import style0 from "./EditTourInfoPropertiesBox.vue?vue&type=style&index=0&id=6ae64b4d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae64b4d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VIcon,VOverlay})

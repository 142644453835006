<template>
    <div class="mt-3">
        <v-data-table hide-default-footer :headers="headers" :items="list" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable" @click="edit(row.item)">
                        <strong>
                            {{ row.item.priority }}
                        </strong>
                    </td>
                    <td class="text-right" style="width:68px">
                        <v-tooltip v-if="row.item.priority != 1" right>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="increasePriority(row.item)">
                                    <v-icon>arrow_upward</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Increase priority") }}</span>
                        </v-tooltip>
                    </td>
                    <td class="text-right" style="width:68px">
                        <v-tooltip v-if="row.item.priority != list.length" right>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="decreasePriority(row.item)">
                                    <v-icon>arrow_downward</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Decrease priority") }}</span>
                        </v-tooltip>
                    </td>
                    <td class="clickable" style="width:150px" @click="edit(row.item)">
                        {{ row.item.ietfLanguageTag }}
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        {{ row.item.name }}
                    </td>

                    <td class="text-right nobreak" style="width:68px">
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-layout class="mt-5 mb-5">
            <v-layout justify-center>
                <v-btn color="primary" class="pl-3" @click="add">
                    <v-icon class="mr-2">
                        add
                    </v-icon>
                    {{ l("Add Language") }}
                </v-btn>
            </v-layout>
        </v-layout>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import LanguageService from "../../services/LanguageService.js";
    import ListUtils from "../../utils/ListUtils.js";

    export default ListUtils.setupList({
        headers: [
            { text: "Priority", sortable: false, width: "1%" },
            { text: "", sortable: false, width: "1%" },
            { text: "", sortable: false, width: "1%" },
            { text: "IETF language tag", sortable: false },
            { text: "Name", sortable: false },
            { text: "", sortable: false }
        ],
        update() {
            this.updateSectionTitle(this.l("Languages"));
            this.showLoading();
            LanguageService.getLanguages(list => {
                this.list = list;
                this.list.sort((a, b) => {
                    if (a.priority < b.priority) {
                        return -1;
                    }
                    if (a.priority > b.priority) {
                        return 1;
                    }
                    return 0;
                });
                this.hideLoading();
            });
        },
        deleteSettings : {
            promptTitle: "Delete Language?",
            promptQuestion: "Are you sure you want to delete \"${v.name}\"?",
            action: LanguageService.deleteLanguage,
            success: "Language \"${v.name}\" deleted."
        },
        itemsPerPage: -1,
        methods: {
            increasePriority(row) {
                if (row.priority > 1) {
                    LanguageService.updateLanguage({
                        _id: row._id,
                        name: row.name,
                        priority: row.priority - 1.5
                    }, () => {
                        this.update();
                        this.$root.$emit("success", this.l("Language priorities updated."));
                        this.$emit("editedEntry");
                    });
                }
                
            },
            decreasePriority(row) {
                if (row.priority < this.list.length) {
                    LanguageService.updateLanguage({
                        _id: row._id,
                        name: row.name,
                        priority: row.priority + 1.5
                    }, () => {
                        this.update();
                        this.$root.$emit("success", this.l("Language priorities updated."));
                        this.$emit("editedEntry");
                    });
                }
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

</style>
<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import LanguageService from "../../services/LanguageService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            WindowedForm
        },
        data() {
            return {
                form: createForm({

                    title: "Add Language",

                    action: LanguageService.insertLanguage,

                    fieldDefs: [
                        {
                            key: "ietfLanguageTag",
                            type: "Text",
                            label: "IETF language tag",
                            required: true
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            required: true
                        }
                    ],

                    submitLabel: "Add",

                    submitted: entry => {
                        this.$root.$emit("success", l("Language \"${v.name}\" added.", entry));
                        this.$emit("createdEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>
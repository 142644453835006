<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import LanguageService from "../../services/LanguageService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Edit Language ${v.ietfLanguageTag}",
                        entry: this.entry
                    },

                    action: LanguageService.updateLanguage,

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            value: this.entry.name,
                            required: true
                        },
                        {
                            key: "priority",
                            type: "Hidden",
                            value: this.entry.priority
                        }
                    ],

                    submitLabel: "Save",

                    submitted: entry => {
                        this.$root.$emit("success", l("Changes to language \"${v.name}\" saved.", entry));
                        this.$emit("editedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>
import { render, staticRenderFns } from "./LocalizedField.vue?vue&type=template&id=7f2775e6&scoped=true&"
import script from "./LocalizedField.vue?vue&type=script&lang=js&"
export * from "./LocalizedField.vue?vue&type=script&lang=js&"
import style0 from "./LocalizedField.vue?vue&type=style&index=0&id=7f2775e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f2775e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VSubheader,VTab,VTabItem,VTabs})

import { render, staticRenderFns } from "./UsageLimitsList.vue?vue&type=template&id=08a54289&scoped=true&"
import script from "./UsageLimitsList.vue?vue&type=script&lang=js&"
export * from "./UsageLimitsList.vue?vue&type=script&lang=js&"
import style0 from "./UsageLimitsList.vue?vue&type=style&index=0&id=08a54289&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a54289",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VProgressLinear,VSimpleTable})
